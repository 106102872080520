// import { logActivity } from './commonFunctions'
import { microsoftSsoCheckUser } from './requests'

export async function checkInWithMicrosoft(
  email: string,
  attempt: number = 0,
): Promise<boolean> {
  let res = await microsoftSsoCheckUser(email)

  if (!('errorCode' in res)) {
    console.log(
      'Checked in with Microsoft SSO, user exists',
      new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date()),
    )
    return true
  } else {
    console.log(res)
    if (
      res.errorCode === 401 &&
      res.message &&
      res.message === 'user no longer in Microsoft'
    ) {
      attempt += 1

      console.log('attempt')
      console.log(attempt)

      if (attempt <= 2) {
        await new Promise((resolve) => setTimeout(resolve, 30000)) // 30 seconds
        return checkInWithMicrosoft(email, attempt) // Recursive retry
      } else {
        console.log(
          'Checked in with Microsoft SSO, user no longer in Microsoft',
          new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date()),
        )

        return false
      }
    }
  }
  return true
}
