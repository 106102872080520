import React, { FormEvent, useState } from 'react'
import SettingInfoRow from '../../components/SettingInfoRow'
import tickedImg from '../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../resources/images/018-untickedGrayBlue.svg'
import Button from '../../../../../Components/Buttons/Button/Button'
import SettingPopUp from '../../components/SettingPopUp'
import InputText from '../../../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../../../services/textFunctions'
import {
  deleteAccount,
  deleteOwnSSOAccount,
  transferCases,
} from '../../../../../services/requests'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import OnlyOwnerPopUp from '../../components/OnlyOwnerPopUp'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../../../services/commonFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { User } from '../../../../../models/user'
import { featuresState } from '../../../../../states/FeaturesState'
import { UserRole } from '../../../../../models/enums'

const DeleteAccount = () => {
  const [user, setUser] = useRecoilState(userState)
  const features = useRecoilValue(featuresState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const [agree, setAgree] = useState(false)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [emailError, setEmailError] = useState<
    undefined | 'wrongEmail' | 'invalidEmail'
  >(undefined)
  const [wrongPassword, setWrongPassword] = useState(false)
  const [onlyOwnerData, setOnlyOwnerData] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onClick = () => {
    setAgree((prevState) => !prevState)
  }

  const onSubmit = (e?: FormEvent<HTMLFormElement>, force?: boolean) => {
    e?.preventDefault()

    if (features.use_microsoft_sso) {
      if (email.trim() === '' || !emailPattern.test(email)) {
        setEmailError('invalidEmail')
        return
      }
      if (email.trim() !== user.email) {
        setEmailError('wrongEmail')
        return
      }

      setEmailError(undefined)
      setLoading(true)
      setTimeout(() => {
        //Mixpanel 101
        logActivity(
          false,
          'Pressed "DELETE ACCOUNT" after entering email in Account Details in SSO mode',

          deleteOwnSSOAccount(email, force).then((res) => {
            if ('errorCode' in res) {
              if (
                res.errorCode === 403 &&
                res.message.error &&
                res.message.error.includes('only owner')
              ) {
                setOnlyOwnerData(res.message)
              } else {
                setWrongPassword(true)
              }
            } else {
              setUser(User.EmptyUser())
              navigate('/', { replace: true })
            }

            setLoading(false)
          }),
        )
      })
    } else {
      if (password.trim() === '') {
        setPasswordError(true)
        return
      }

      setPasswordError(false)
      setLoading(true)
      setTimeout(() => {
        //Mixpanel 101
        logActivity(
          false,
          'Pressed "DELETE ACCOUNT" after entering password in Account Details',
        )

        deleteAccount(password, force).then((res) => {
          if ('errorCode' in res) {
            if (
              res.errorCode === 403 &&
              res.message.error &&
              res.message.error.includes('only owner')
            ) {
              setOnlyOwnerData(res.message)
            } else {
              setWrongPassword(true)
            }
          } else {
            setUser(User.EmptyUser())
            navigate('/', { replace: true })
          }

          setLoading(false)
        })
      }, 1000)
    }
  }

  const onTransferCases = (usernames: string[]) => {
    setLoading(true)
    const body = usernames
      .map((username, index) => ({
        user: username,
        case: onlyOwnerData.groups[index].groupid as string,
      }))
      .filter((ctu) => ctu.user !== 'ADMIN')

    if (body.length > 0) {
      transferCases({ case_to_user: [...body] }).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'transferCases'),
        )

        if (!('errorCode' in res)) {
          setOnlyOwnerData(undefined)
          onSubmit(undefined, true)
        } else {
          setLoading(false)
        }
      })
    } else {
      onSubmit(undefined, true)
    }
  }

  const onExit = () => {
    if (!loading) {
      setOpenPopUp(false)
      setOnlyOwnerData(undefined)
      setPassword('')
      setWrongPassword(false)
      setPasswordError(false)
    }
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="PopUp"
        data-textattribute="title-170"
      >
        {getText('title-170', user.settings)}
      </h1>
      <SettingInfoRow
        id="deleteAccountInfo"
        descriptionText={getText('description-105', user.settings)}
        descriptionTextAttribute={'description-105'}
        isFirst
      />
      <div className={`tool-component-input-container marginLeft3`}>
        <img
          src={agree ? tickedImg : unTickedImg}
          alt={agree ? 'tickedImg' : 'unTickedImg'}
          className="tickImg"
          id="agreeDeleteAccount"
          tabIndex={1008}
          onClick={onClick}
          style={{ cursor: 'pointer', marginRight: '10px' }}
        />
        <p
          onClick={onClick}
          id="agreeDeleteAccountText"
          data-textattribute="submenu-18"
          style={{ cursor: 'pointer', marginRight: '30px' }}
        >
          {getText('submenu-18', user.settings)}
        </p>
      </div>
      <div
        className={`tool-component-input-container marginLeft3`}
        style={{ marginTop: 15 }}
      >
        <Button
          id="deleteAccountButton"
          buttonText={getText('button-92', user.settings)}
          buttonTextAttribute={'button-92'}
          buttonType={`${agree ? 'contained' : 'nonactive'}`}
          disabled={!agree}
          danger
          className="createCaseButton"
          type="button"
          onClick={() => {
            setOpenPopUp(true)
          }}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
      {openPopUp && (
        <SettingPopUp
          onClose={onExit}
          small={!onlyOwnerData}
          onlyOwner={onlyOwnerData !== undefined}
        >
          {onlyOwnerData ? (
            <OnlyOwnerPopUp
              onClose={onExit}
              data={onlyOwnerData}
              onTransferCases={onTransferCases}
              loading={loading}
            />
          ) : features.use_microsoft_sso &&
            user.role !== UserRole.EPEROTO_ADMIN ? (
            <form className="form" onSubmit={onSubmit}>
              <div className="welcomeMessageContainer">
                <h2
                  className="welcome"
                  id="deleteAccountPopUpTitle"
                  data-textattribute="title-171"
                >
                  {getText('title-171', user.settings)}
                </h2>
                <p
                  className="instructions"
                  id="resetPasswordInstructions"
                  data-textattribute="description-00"
                  //newText
                >
                  You are about to delete your account. To confirm the deletion
                  enter your email address.
                </p>
              </div>
              <div className="inputsContainer">
                <div className="input for-settings">
                  <InputText
                    label={getText('label-80', user.settings)}
                    labelTextAttribute="label-80"
                    id="emailForDelete"
                    width={470}
                    onChange={(e: any) => {
                      setEmail(e.target.value)
                    }}
                    value={password}
                    name="emailForDelete"
                    error={emailError !== undefined}
                    errorMessage={
                      //newText
                      emailError === 'invalidEmail'
                        ? getText('error-38', user.settings)
                        : "This email doesn't match with the email of this account"
                    }
                    errorTextAttribute={
                      emailError === 'invalidEmail' ? 'error-38' : 'error-00'
                    }
                    tabIndex={1}
                    alwaysShrink
                  />
                </div>
              </div>
              <div className="submitButtonContainer row">
                <Button
                  id="cancelDeleteAccount"
                  buttonText={getText('button-93', user.settings)}
                  buttonTextAttribute={'button-93'}
                  buttonType="outlined"
                  className="createCaseButton"
                  type="button"
                  onClick={onExit}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
                <div style={{ width: '40px' }}></div>
                <button
                  type="submit"
                  className="button submitButton small button-type-contained"
                  id="deleteAccountButton"
                  data-textattribute="button-113"
                  style={{ backgroundColor: '#ac2828', color: 'white' }} //greekEaster
                >
                  {loading ? (
                    <CircularProgress size={22} />
                  ) : (
                    getText('button-113', user.settings)
                  )}
                </button>
              </div>
            </form>
          ) : (
            <form className="form" onSubmit={onSubmit}>
              <div className="welcomeMessageContainer">
                <h2
                  className="welcome"
                  id="deleteAccountPopUpTitle"
                  data-textattribute="title-171"
                >
                  {getText('title-171', user.settings)}
                </h2>
                <p
                  className="instructions"
                  id="resetPasswordInstructions"
                  data-textattribute="description-131"
                >
                  {getText('description-131', user.settings)}
                </p>
              </div>
              <div className="inputsContainer">
                <div className="input for-settings">
                  <InputText
                    label={getText('label-79', user.settings)}
                    labelTextAttribute="label-79"
                    id="passwordForDelete"
                    type="password"
                    width={470}
                    onChange={(e: any) => {
                      setPassword(e.target.value)
                      setWrongPassword(false)
                      setPasswordError(false)
                    }}
                    value={password}
                    name="passwordForDelete"
                    error={passwordError || wrongPassword}
                    errorMessage={
                      wrongPassword
                        ? getText('error-75', user.settings)
                        : getText('error-45', user.settings)
                    }
                    errorTextAttribute={wrongPassword ? 'error-75' : 'error-45'}
                    tabIndex={1}
                    alwaysShrink
                  />
                </div>
              </div>
              <div className="submitButtonContainer row">
                <Button
                  id="cancelDeleteAccount"
                  buttonText={getText('button-93', user.settings)}
                  buttonTextAttribute={'button-93'}
                  buttonType="outlined"
                  className="createCaseButton"
                  type="button"
                  onClick={onExit}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
                <div style={{ width: '40px' }}></div>
                <button
                  type="submit"
                  className="button submitButton small button-type-contained"
                  id="deleteAccountButton"
                  data-textattribute="button-113"
                  style={{ backgroundColor: '#ac2828', color: 'white' }} //greekEaster
                >
                  {loading ? (
                    <CircularProgress size={22} />
                  ) : (
                    getText('button-113', user.settings)
                  )}
                </button>
              </div>
            </form>
          )}
        </SettingPopUp>
      )}
    </>
  )
}

export default DeleteAccount
