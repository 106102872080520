import { useEffect, useRef } from 'react'
import { microsoftSsoGetUser } from '../../../services/requests'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { User } from '../../../models/user'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../services/commonFunctions'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { userState } from '../../../states/UserState'
import { AnimationDots } from '../../../Components/Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../../services/constants'
import logo from '../../../resources/images/Eperoto-logo-blue.svg'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'

const MicrosoftLoginRedirectPage = () => {
  const initRequest = useRef(false)
  const searchParams = useSearchParams()[0]
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const navigate = useNavigate()
  const setUser = useSetRecoilState(userState)
  console.log(
    'MicrosoftLoginRedirectPage Component is shown, redirection from microsoft works',
  )

  useEffect(() => {
    if (!initRequest.current) {
      microsoftSsoGetUser(searchParams.get('code') ?? '').then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'microsoftSsoGetUser'),
        )

        if (!('errorCode' in res)) {
          const newUser = User.UserFromDB(res.data)
          setUser(newUser)

          //Mixpanel 1
          logActivity(false, 'Logged in via Microsoft SSO')
        } else {
          if (
            res.message &&
            res.message.unauthorized_user &&
            res.message.unauthorized_user.length > 0
          ) {
            console.log('logout happened')
            console.log(res.message.unauthorized_user)
            setUser(User.EmptyUserMicrosoftSSO(res.message.unauthorized_user))
          } else {
            setUser(User.EmptyUser())
          }
        }
        const redirectUrlFromLocalStorage = localStorage.getItem('redirectUrl')
        if (redirectUrlFromLocalStorage !== '') {
          navigate('/' + redirectUrlFromLocalStorage)
        } else {
          navigate('/')
        }
      })
      initRequest.current = true
    }

    // eslint-disable-next-line
  }, [])

  //newText
  return (
    <div className="mainContainer">
      <div className="logoContainer">
        <img className="logoImg" src={logo} alt="Eperoto" />
      </div>
      <div className="messageContainer">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="messageText">Logging into Eperoto...</p>
          <AnimationDots speed={ANIMATION_DOTS_SPEED} />
        </div>
      </div>
    </div>
  )
}

export default MicrosoftLoginRedirectPage
